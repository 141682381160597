/* eslint-disable consistent-return */

/* eslint-disable react-hooks/exhaustive-deps */
import { useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  selectAnotherUserGuaranteedResponsePrice,
  selectAnotherUserVideoResponsePrice,
} from 'ducks/user';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { flexbox } from 'styled-system';

import noop from 'tools/noop';

import { Avatar } from 'components/avatar';
import { ButtonGoBack, MButton } from 'components/buttons';
import { MBox } from 'components/common';
import { IconBoost, IconVideo } from 'components/icons';
import { MessageTextarea } from 'components/messenger';
import { ErrorNotification } from 'components/notifications';
import { Heading1, Text } from 'components/texts';
import { VoiceRecorder } from 'components/voiceMessage';

import {
  ButtonDisabledStyled,
  CustomScrollBar,
  InfiniteScrollerStyled,
  VisuallyHidden,
} from 'styles/MixinStyle';
import { breakpoints, gradients, theme } from 'styles/Theme';

import Header from './helpers/Header';

const ChatLayoutStyled = styled.section`
  height: 100vh;
  height: fill-available;
  height: 100dvh; /* stylelint-disable-line */
  overflow: hidden;
  ${flexbox};
  .react-recording {
    ~ .react-video {
      display: none;
    }
  }
`;

const HeaderStyled = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 101;
  width: 100%;
  padding: 0 ${theme.space.defaultSpaceX};
  height: ${theme.sizes.stickyChatHeader};
`;

const HeaderContentStyled = styled.div`
  background: ${theme.colors.white};
  margin: 0 -${theme.space.primaryMd};
  z-index: 99;
  height: ${theme.sizes.stickyChatHeader};
`;

const MainStyled = styled.main`
  max-width: ${breakpoints.md};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: ${theme.space.defaultSpaceX};
  padding-bottom: ${theme.space.primaryMd};
`;

const FooterContenStyled = styled.div`
  max-width: ${breakpoints.md};
  margin: 0 auto;
  width: 100%;
  ${props =>
    props.isFan &&
    `padding: ${theme.space.sm} ${theme.space.sm} ${theme.space.primarySm} ${theme.space.sm}`};
`;

const FooterStyled = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;
  background: ${theme.colors.white};
  z-index: 101;
  ${props =>
    !props.isFan && `border-top: 1px solid ${theme.colors.grayLighter};`};
`;

// boost button for fan
const BoostButtonStyled = styled.button`
  color: ${theme.colors.white};
  background: ${gradients.secondary};
  border-radius: ${theme.radii.xl};
  padding: ${theme.space.md} ${theme.space.primaryMd};
  margin-left: ${theme.space.sm};
  display: flex;
  align-items: center;
  align-self: end;
  ${props => props.disabled && ButtonDisabledStyled};
`;

const AvatarLinkStyled = styled(Link)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: ${theme.radii.circle};
`;

/* stylelint-disable */
const InfiniteScrollerContainerStyled = styled.div`
  height: calc(100vh - ${props => `${props.height}px`} - 10rem);
  height: calc(100svh - ${props => `${props.height}px`});
  ${CustomScrollBar};
  ${InfiniteScrollerStyled};
  @-moz-document url-prefix() {
    height: calc(100vh - ${props => `${props.height}px`});
  }
`;

const ChatLayout = ({
  name,
  surname,
  isFan,
  isCreator,
  isAccountDelete,
  disabledVoiceButton,
  avatarSrc,
  avatarSrcAvif,
  avatarSrcWebp,
  onGoBackButtonClick,
  messageValue,
  onChangeMessage,
  onSendClick,
  onBoostClick,
  onMediaChange,
  onVideoRecord,
  handleAudioFileCreation,
  handleAudioFileDeletion,
  onSendAudioClick,
  pathToCreatorProfile,
  infiniteRef,
  isDisabled,
  isBoostDisabled,
  ...props
}) => {
  const [heightFooter, setHeightFooter] = useState(68);
  const footerSticky = useRef(null);
  const stickyHeader = document.querySelector('#headerSticky');

  const guaranteedResponsePrice = useSelector(
    selectAnotherUserGuaranteedResponsePrice,
  );
  const videoResponsePrice = useSelector(selectAnotherUserVideoResponsePrice);

  useLayoutEffect(() => {
    if (footerSticky.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (footerSticky.current.offsetHeight) {
          setHeightFooter(footerSticky.current.offsetHeight);
        }
      });
      resizeObserver.observe(footerSticky.current);
      return function cleanup() {
        resizeObserver.disconnect();
      };
    }
  }, [footerSticky.current]);

  const boxShadowHandler = e => {
    if (stickyHeader) {
      if (e.target.scrollTop > 10) {
        stickyHeader.style.boxShadow = `${theme.shadows.secondary}`;
      } else {
        stickyHeader.style.boxShadow = `none`;
      }
    }
  };

  return (
    <ChatLayoutStyled aria-labelledby="chatMessage" role="region" {...props}>
      <HeaderStyled id="headerSticky">
        <Heading1 id="chatMessage">
          <VisuallyHidden>Message de conversation</VisuallyHidden>
        </Heading1>
        <HeaderContentStyled>
          <MBox
            display="grid"
            gridTemplateColumns="auto auto 1fr"
            alignItems="center"
            gap={theme.space.primaryMd}
            maxWidth={breakpoints.md}
            height="100%"
            mx="auto"
            py="primaryMd"
            px="defaultSpaceX"
          >
            <ButtonGoBack onClick={onGoBackButtonClick} />
            <MBox position="relative">
              <Avatar
                type="xxs"
                $src={avatarSrc}
                $srcSetAvif={avatarSrcAvif}
                $srcSetWebp={avatarSrcWebp}
                personName={name}
              />
              {isFan && (
                <AvatarLinkStyled to={pathToCreatorProfile}>
                  <VisuallyHidden>
                    lien vers le profil {name} {surname}
                  </VisuallyHidden>
                </AvatarLinkStyled>
              )}
            </MBox>
            <Text
              as="h2"
              type="subtitle1"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {isFan ? (
                <Text as={Link} to={pathToCreatorProfile} color="black">
                  <VisuallyHidden>lien vers le profil</VisuallyHidden>
                  {name} {surname}
                </Text>
              ) : (
                <>
                  {name} {surname}
                </>
              )}
            </Text>
          </MBox>
        </HeaderContentStyled>
      </HeaderStyled>
      <MainStyled>
        <InfiniteScrollerContainerStyled
          id="infiniteScroll"
          onScroll={boxShadowHandler}
          ref={infiniteRef}
          height={64 + heightFooter + (isFan ? 16 : 32)}
        >
          {props.main}
        </InfiniteScrollerContainerStyled>
      </MainStyled>
      <FooterStyled isFan={isFan} ref={footerSticky}>
        <FooterContenStyled isFan={isFan}>
          {isAccountDelete && (
            <ErrorNotification
              mt={isCreator ? 'sm' : null}
              mx={isCreator ? 'sm' : null}
              mb={isCreator ? '0' : null}
              text={
                <>
                  Ce {isFan ? 'créateur' : 'fan'} n’est plus sur Premium et ne
                  peut plus recevoir de message.
                </>
              }
            />
          )}
          {isCreator && (
            <MBox display="flex" p="primaryMd">
              <VoiceRecorder
                isMessage
                buttonRecordText="Vocal"
                disabledVoiceButton={
                  !!disabledVoiceButton || isAccountDelete || isDisabled
                }
                handleAudioFileCreation={handleAudioFileCreation}
                handleAudioFileDeletion={handleAudioFileDeletion}
                handleButtonClick={onSendAudioClick}
              />
              <MButton
                rounded
                icon
                width="100%"
                className="react-video"
                onClick={onVideoRecord}
                disabled={isAccountDelete || isDisabled}
                ml="md"
              >
                Vidéo
                <Text
                  as="span"
                  display="flex"
                  justifyContent="center"
                  ml="primarySm"
                  height="2rem"
                >
                  <IconVideo />
                </Text>
              </MButton>
            </MBox>
          )}
          {isFan && (
            <MBox display="grid" gridTemplateColumns="1fr auto">
              <MessageTextarea
                messageValue={messageValue}
                onChange={onChangeMessage}
                onSendClick={onSendClick}
                onMediaChange={onMediaChange}
                isAccountDelete={isAccountDelete || isDisabled}
                placeholder="Votre message..."
                isBoostDisabled={isBoostDisabled}
                rightWidget={
                  <BoostButtonStyled
                    disabled={
                      isAccountDelete ||
                      isDisabled ||
                      (!guaranteedResponsePrice && !videoResponsePrice)
                    }
                    type="button"
                    aria-label="Ouvrir modal avec message boost"
                    onClick={onBoostClick}
                  >
                    <Text as="span" type="subtitle1" mr="primarySm">
                      Boost
                    </Text>
                    <IconBoost />
                  </BoostButtonStyled>
                }
              />
            </MBox>
          )}
        </FooterContenStyled>
      </FooterStyled>
    </ChatLayoutStyled>
  );
};

ChatLayout.propTypes = {
  main: propTypes.node.isRequired,
  name: propTypes.string,
  avatarSrc: propTypes.string,
  avatarSrcAvif: propTypes.string,
  avatarSrcWebp: propTypes.string,
  surname: propTypes.string,
  isFan: propTypes.bool,
  isCreator: propTypes.bool,
  isAccountDelete: propTypes.bool,
  disabledVoiceButton: propTypes.any,
  onGoBackButtonClick: propTypes.func,
  messageValue: propTypes.string,
  onChangeMessage: propTypes.func,
  onSendClick: propTypes.func,
  onBoostClick: propTypes.func,
  onMediaChange: propTypes.func,
  onVideoRecord: propTypes.func,
  handleAudioFileCreation: propTypes.func,
  handleAudioFileDeletion: propTypes.func,
  onSendAudioClick: propTypes.func,
  pathToCreatorProfile: propTypes.string,
  infiniteRef: propTypes.any,
  isDisabled: propTypes.bool,
};

ChatLayout.defaultProps = {
  messageValue: '',
  onChangeMessage: noop,
  onSendClick: noop,
  onBoostClick: noop,
  onMediaChange: noop,
  onVideoRecord: noop,
  handleAudioFileCreation: noop,
  handleAudioFileDeletion: noop,
  onSendAudioClick: noop,
  pathToCreatorProfile: '/',
  isDisabled: false,
};

export default ChatLayout;
