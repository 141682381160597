import { useEffect, useState } from 'react';

import differenceInSeconds from 'date-fns/differenceInSeconds';

const TimerWidget = ({ deadline }) => {
  const ONE_HOUR = 3600;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    const timerId = setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  const diffInSeconds = differenceInSeconds(new Date(deadline), currentTime);

  const hours = diffInSeconds <= 1 ? 0 : Math.floor(diffInSeconds / ONE_HOUR);
  const minutes =
    diffInSeconds <= 1
      ? 0
      : Math.floor((diffInSeconds - hours * ONE_HOUR) / ONE_MINUTE);
  const seconds =
    diffInSeconds <= 1
      ? 0
      : diffInSeconds - hours * ONE_HOUR - minutes * ONE_MINUTE;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export default TimerWidget;
