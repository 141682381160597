/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getUserSuccess } from 'ducks/user';

import { MIN_ITEMS_PER_PAGE_VALUE } from 'constants/numericConstants';
import { serverEventsTypes } from 'constants/serverEventsTypes';

import { initialState } from './initialState';

const name = 'dialog';

export const dialogSlice = createSlice({
  name,
  initialState,
  reducers: {
    getDialogsRequest(state, { payload }) {
      state.loading = true;
      state.searchState = payload?.publicName;
      state.sortState = payload?.sort;
    },
    getDialogsSuccess(state, { payload }) {
      state.loading = false;
      state.awaitingSum = payload?.awaitingSum;
      state.mySubscribers = payload?.mySubscribers;
      state.dialogsCollection =
        payload?.data.length !== 0
          ? [...state.dialogsCollection, ...payload?.data]
          : state.dialogsCollection;
      state.hasMore = payload?.data.length === 20;
    },
    getDialogsFailure(state) {
      state.loading = false;
      state.hasMore = false;
    },
    getDialogsBySearchRequest(state, { payload }) {
      state.loading = true;
      state.searchState = payload?.name;
      state.sortState = payload?.sort;
    },
    getDialogsBySearchSuccess(state, { payload }) {
      state.loading = false;
      state.dialogsCollection =
        payload?.length !== 0
          ? [...state.dialogsCollection, ...payload]
          : state.dialogsCollection;
      state.hasMore = payload?.length === 20;
    },
    getNewDialogsBySearchSuccess(state, { payload }) {
      state.loading = false;
      state.dialogsCollection = payload;
      state.hasMore = payload?.length === 20;
    },
    getDialogsBySearchFailure(state) {
      state.loading = false;
      state.hasMore = false;
    },
    getDialogsWithFiltersSuccess(state, { payload }) {
      state.loading = false;
      state.isDialogsFirstLoaded = true;
      state.awaitingSum = payload?.awaitingSum;
      state.dialogsCollection = payload?.data;
      state.mySubscribers = payload?.mySubscribers;
      state.hasMore = payload?.data.length === 20;
    },
    getDialogsByReceiverIdSuccess(state, { payload }) {
      state.loading = false;
      state.dialogsByReceiverId = payload?.data;
    },
    clearDialogsByReceiverId(state) {
      state.dialogsByReceiverId = [];
    },
    updateDialogRequest(state) {
      state.loading = true;
    },
    updateDialogSuccess(state) {
      state.loading = false;
    },
    updateDialogFailure(state) {
      state.loading = false;
    },
    getDialogByCreatorNicknameRequest(state) {
      state.loading = true;
    },
    getDialogByCreatorNicknameSuccess(state, { payload }) {
      state.loading = false;
      state.dialog = payload;
    },
    getDialogByCreatorNicknameFailure(state) {
      state.loading = false;
    },
    getDialogByFanNicknameRequest(state) {
      state.loading = true;
    },
    getDialogByFanNicknameSuccess(state, { payload }) {
      state.loading = false;
      state.dialog = payload;
    },
    getDialogByFanNicknameFailure(state) {
      state.loading = false;
    },
    getDialogByIdRequest(state) {
      state.loading = true;
    },
    getDialogByIdSuccess(state, { payload }) {
      state.loading = false;
      state.isDialogRequestFirstLoaded = true;
      state.dialog = payload;
    },
    getDialogByIdFailure(state) {
      state.loading = false;
      state.isDialogRequestFirstLoaded = true;
    },
    resetError(state) {
      state.error = null;
    },
    incrementDialogsPage(state) {
      state.dialogsPage += 1;
    },
    decrementDialogsPage(state) {
      state.dialogsPage = 1;
    },
    resetHasMore(state) {
      state.hasMore = true;
    },
    resetHasTransferedMedia(state) {
      state.hasTransferedMedia = false;
    },
    setHasTransferedMedia(state) {
      state.hasTransferedMedia = true;
    },
    resetDialogState: () => initialState,
    resetDialogListData(state) {
      const newState = initialState;
      return { ...newState, unreadMessages: state.unreadMessages };
    },
    clearCurrentDialogData(state) {
      state.dialog = null;
      state.isGuaranteedResponsePending = false;
      state.isDialogRequestFirstLoaded = false;
    },
    setUnreadMessages(state, { payload }) {
      state.unreadMessages = payload;
    },
    resetUnreadMessages(state) {
      state.unreadMessages = null;
    },
    updateDialogList(state, { payload }) {
      if (payload) {
        if (
          !state.dialogMessages?.length ||
          !state.dialogMessages.find(message => payload.id === message.id)
        ) {
          state.dialogMessages.unshift(payload);
        } else if (state.dialogMessages?.length) {
          state.dialogMessages = state.dialogMessages.map(message => {
            if (payload.id === message.id) {
              return payload;
            }
            return message;
          });
        }
      }

      if (
        state.dialogsCollection?.length &&
        state.dialogsCollection.find(dialog => payload.id === dialog.id)
      ) {
        state.dialogsCollection = state.dialogsCollection.filter(
          dialog => payload.id !== dialog.id,
        );
      }
      state.dialogsCollection.unshift(payload);
    },
    getNewUnreadMessage(state) {
      state.unreadMessages += 1;
    },
    getDialogMediaObjectsRequest(state) {
      state.loading = true;
      state.error = false;
    },
    getDialogMediaObjectsSuccess(state, { payload }) {
      state.loading = false;
      state.error = false;
      state.sharedMediaObjects =
        payload.length !== 0
          ? [...state.sharedMediaObjects, ...payload]
          : state.sharedMediaObjects;
      state.hasMoreDialogMediaObjects =
        payload.length === MIN_ITEMS_PER_PAGE_VALUE;
    },
    getDialogMediaObjectsFailure(state) {
      state.loading = false;
      state.error = true;
      state.hasMoreDialogMediaObjects = false;
    },
    resetDialogMediaObjects(state) {
      state.sharedMediaObjects = [];
    },
    incrementDialogMediaObjectsPage(state) {
      state.sharedMediaObjectsPage += 1;
    },
    resetDialogMediaObjectsPage(state) {
      state.sharedMediaObjectsPage = 1;
    },
    resetHasMoreDialogMediaObjects(state) {
      state.hasMoreDialogMediaObjects = true;
    },
    setIsOnDialogPage(state, { payload }) {
      state.isOnDialogPage = payload;
    },
    setIsGuaranteedResponsePending(state, { payload }) {
      state.isGuaranteedResponsePending = payload;
    },
    setGuaranteedAnswerUntil(state, { payload }) {
      state.guaranteedAnswerUntil = payload;
    },
    setIsVideoResponseRequested(state, { payload }) {
      state.isVideoResponseRequested = payload;
    },
    getSecondDialogsRequest(state) {
      state.loading = true;
    },
    getSecondDialogsSuccess(state, { payload }) {
      state.loading = false;
      state.dialogsSecondCollection =
        payload?.data.length !== 0
          ? [...state.dialogsSecondCollection, ...payload?.data]
          : state.dialogsSecondCollection;
      state.hasMoreSecondDialogs = payload?.data.length === 20;
    },
    getSecondDialogsNewDataSuccess(state, { payload }) {
      state.loading = false;
      state.dialogsSecondCollection = payload?.data;
      state.hasMoreSecondDialogs = payload?.data.length === 20;
    },
    getSecondDialogsFailure(state) {
      state.loading = false;
      state.hasMoreSecondDialogs = false;
    },
    incrementSecondDialogsPage(state) {
      state.secondDialogsPage += 1;
    },
    decrementSecondDialogsPage(state) {
      state.secondDialogsPage = 1;
    },
    resetHasMoreSecondDialogs(state) {
      state.hasMoreSecondDialogs = true;
    },
    reseSecondDialogsCollection(state) {
      state.dialogsSecondCollection = [];
    },
  },
  /* extraReducers: {
    [serverEventsTypes.DIALOG_READ]: (state, { payload }) => {
      if (payload.read && state.unreadMessages >= payload.messagesRead) {
        state.unreadMessages -= payload.messagesRead;
      }
      if (state.dialogsCollection?.length) {
        state.dialogsCollection = state.dialogsCollection.map(dialog => {
          if (payload.dialogId === dialog.id) {
            return {
              ...dialog,
              read: true,
            };
          }
          return dialog;
        });
      }
    },
    [getUserSuccess]: (state, { payload }) => {
      if (payload.unreadDialogs) {
        state.unreadMessages = payload.unreadDialogs;
      }
    },
  }, */
  extraReducers: builder => {
    builder
      .addCase(serverEventsTypes.DIALOG_READ, (state, { payload }) => {
        state.unreadMessages = payload.unreadMessages;
        state.awaitingSum = payload?.awaitingSum;
        if (state.dialogsCollection?.length) {
          state.dialogsCollection = state.dialogsCollection.map(dialog => {
            if (payload.id === dialog.id) {
              return {
                ...dialog,
                read: true,
              };
            }
            return dialog;
          });
        }
      })
      .addCase(getUserSuccess, (state, { payload }) => {
        if (payload.unreadDialogs) {
          state.unreadMessages = payload.unreadDialogs;
        }
      });
  },
});

export const {
  resetHasTransferedMedia,
  setHasTransferedMedia,
  getDialogsRequest,
  getDialogsSuccess,
  getDialogsFailure,
  updateDialogRequest,
  updateDialogSuccess,
  updateDialogFailure,
  getDialogByCreatorNicknameRequest,
  getDialogByCreatorNicknameSuccess,
  getDialogByCreatorNicknameFailure,
  getDialogByFanNicknameRequest,
  getDialogByFanNicknameSuccess,
  getDialogByFanNicknameFailure,
  resetError,
  incrementDialogsPage,
  decrementDialogsPage,
  resetDialogsList,
  resetHasMore,
  getDialogsWithFiltersSuccess,
  getDialogsBySearchRequest,
  getDialogsBySearchSuccess,
  getDialogsBySearchFailure,
  getNewDialogsBySearchSuccess,
  resetDialogState,
  setUnreadMessages,
  resetUnreadMessages,
  getDialogByIdRequest,
  getDialogByIdSuccess,
  getDialogByIdFailure,
  updateDialogList,
  resetDialogListData,
  getNewUnreadMessage,
  getDialogMediaObjectsRequest,
  getDialogMediaObjectsFailure,
  getDialogMediaObjectsSuccess,
  resetDialogMediaObjects,
  incrementDialogMediaObjectsPage,
  resetDialogMediaObjectsPage,
  resetHasMoreDialogMediaObjects,
  getDialogsByReceiverIdSuccess,
  clearDialogsByReceiverId,
  clearCurrentDialogData,
  setIsOnDialogPage,
  setIsGuaranteedResponsePending,
  setGuaranteedAnswerUntil,
  setIsVideoResponseRequested,
  getSecondDialogsRequest,
  getSecondDialogsSuccess,
  getSecondDialogsNewDataSuccess,
  getSecondDialogsFailure,
  incrementSecondDialogsPage,
  decrementSecondDialogsPage,
  resetHasMoreSecondDialogs,
  reseSecondDialogsCollection,
} = dialogSlice.actions;

export default dialogSlice.reducer;
