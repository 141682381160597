import { useCallback } from 'react';

import subYears from 'date-fns/subYears';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { border, space } from 'styled-system';

import { validateStatus } from 'constants/validateStatus';

import noop from 'tools/noop';

import { LoadingSpinner, MBox } from 'components/common';
import { IconCheckInput } from 'components/icons';
import { Text, TextLabel } from 'components/texts';

import {
  GradientBorderRadius,
  InputBaseStyles,
  InputDisabledStyles,
  InputIconStyles,
} from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const MInputLabelStyled = styled.label`
  margin: 0;
  padding: 0;
`;

const MInputWrapperStyled = styled.span`
  display: block;
  position: relative;
`;

const MInputStyled = styled.input`
  ${InputBaseStyles};
  height: ${theme.sizes.inputHeight};
  padding-left: ${props =>
    props.icon && !props.searchInput
      ? `${theme.space.xxl}`
      : `${theme.space.primaryMd}`};
  ${props => props.searchInput && `padding-right: ${theme.space.xxxl}`};

  &:focus {
    outline: none;
    ${GradientBorderRadius(`${gradients.primary}`, `${theme.radii.xs}`)};
  }
  ${props =>
    props.disabled
      ? InputDisabledStyles
      : `
    background: ${theme.colors.white};
    border-color: ${theme.colors.grayLight};
    color: ${theme.colors.black};
  `};
  &[type='date'] {
    + span {
      right: ${theme.space.xxxl};
    }
  }
  ${border};
  ${space};
`;

const IconWrapperStyled = styled.span`
  ${InputIconStyles};
  ${props =>
    props.searchInput
      ? `left: calc(100% - ${theme.space.primaryMd}); transform: translateX(-100%);`
      : `left: ${theme.space.primaryMd};`}
`;

const MInputCheckMarkStyled = styled.span`
  ${InputIconStyles};
  right: ${props =>
    props.searchInput ? `${theme.space.xxxl}` : `${theme.space.primaryMd}`};
`;

const InputInsideInfoStyled = styled(Text)`
  ${InputIconStyles};
  color: ${theme.colors.grayMedium};
  left: ${theme.space.primaryMd};
  font-size: ${theme.fontSizes.md};
  font-weight: ${theme.fontWeights.regular};
`;

const MInput = ({
  label,
  onClear,
  isRequired,
  type,
  icon,
  inputInsideInfo,
  stateStatus,
  errorText,
  helperText,
  placeholder,
  disabled,
  idLabel,
  maxLength,
  searchInput,
  focusRef,
  showErrorTextOnInput,
  withoutStatusIcon,
  onBlur,
  onKeyPress,
  withEuroSign,
  ...props
}) => {
  const { SUCCESS, ERROR, LOADING } = validateStatus;
  const getIconStatus = useCallback(
    status => {
      if (withEuroSign) {
        return '€';
      }
      switch (status) {
        case SUCCESS:
          if (withoutStatusIcon) {
            return null;
          }
          return <IconCheckInput />;
        case ERROR:
          return null;
        case LOADING:
          return <LoadingSpinner my="0" size="16" position="relative" />;
        default:
          return null;
      }
    },
    [ERROR, LOADING, SUCCESS, withEuroSign, withoutStatusIcon],
  );

  return (
    <MBox>
      <MInputLabelStyled>
        {label && <TextLabel isRequired={isRequired}>{label}</TextLabel>}
        <MInputWrapperStyled>
          <MInputStyled
            required={isRequired}
            type={type}
            name={idLabel}
            placeholder={placeholder}
            icon={icon}
            onBlur={onBlur}
            disabled={disabled}
            aria-labelledby={!searchInput ? `${idLabel}Error` : null}
            borderWidth={
              stateStatus === ERROR && !disabled
                ? `${theme.borderWidths.sm}`
                : `${theme.borderWidths.xs}`
            }
            borderStyle="solid"
            borderColor={
              stateStatus === ERROR && !disabled && `${theme.colors.error}`
            }
            pl={inputInsideInfo && `calc(139px + ${theme.space.primaryMd})`}
            {...(type === 'date'
              ? {
                  max: subYears(new Date(), 18).toISOString().split('T')[0],
                  min: subYears(new Date(), 99).toISOString().split('T')[0],
                }
              : {})}
            {...(maxLength ? { maxLength } : {})}
            {...(onKeyPress ? { onKeyPress, onPaste: onKeyPress } : {})}
            searchInput={searchInput}
            {...(focusRef ? { ref: focusRef } : {})}
            {...props}
          />
          {inputInsideInfo && (
            <InputInsideInfoStyled as="span">
              {inputInsideInfo}
            </InputInsideInfoStyled>
          )}
          {icon && (
            <IconWrapperStyled
              searchInput={searchInput}
              {...(searchInput
                ? {
                    onClick: onClear,
                  }
                : {})}
            >
              {icon}
            </IconWrapperStyled>
          )}
          {(((stateStatus === SUCCESS || stateStatus === LOADING) &&
            !disabled) ||
            withEuroSign) && (
            <MInputCheckMarkStyled
              aria-label={`La valeur d'entrée est ${stateStatus}`}
              searchInput={searchInput}
            >
              {getIconStatus(stateStatus)}
            </MInputCheckMarkStyled>
          )}
        </MInputWrapperStyled>
      </MInputLabelStyled>
      {(stateStatus === ERROR || showErrorTextOnInput) && errorText && (
        <Text
          type="body2"
          aria-live="polite"
          role="alert"
          id={`${idLabel}Error`}
          mt="xxs"
          color="error"
        >
          {errorText}
        </Text>
      )}
      {helperText && (
        <Text type="body2" color="grayMedium" mt="xxs" lineHeight="sm">
          {helperText}
        </Text>
      )}
    </MBox>
  );
};

MInput.defaultProps = {
  value: '',
  onChange: noop,
  onClear: noop,
  type: 'text',
  label: null,
  stateStatus: null,
  errorText: null,
  disabled: false,
  isRequired: true,
  helperText: null,
  searchInput: false,
  showErrorTextOnInput: false,
  withoutStatusIcon: false,
};

MInput.propTypes = {
  idLabel: propTypes.string, // please, not delete isRequired! it's important for accessibility
  value: propTypes.string,
  type: propTypes.string,
  label: propTypes.string,
  placeholder: propTypes.string,
  stateStatus: propTypes.string,
  errorText: propTypes.string,
  disabled: propTypes.bool,
  isRequired: propTypes.bool,
  inputInsideInfo: propTypes.string,
  helperText: propTypes.string,
  maxLength: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onChange: propTypes.func,
  onClear: propTypes.func,
  searchInput: propTypes.bool,
  showErrorTextOnInput: propTypes.bool,
  withoutStatusIcon: propTypes.bool,
};

export default MInput;
