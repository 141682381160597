import propTypes from 'prop-types';
import styled from 'styled-components';
import {
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography,
  variant,
} from 'styled-system';

import { GradientText, LineClampStyles } from 'styles/MixinStyle';
import { gradients, theme } from 'styles/Theme';

const TextVariant = () =>
  variant({
    prop: 'type',
    variants: {
      subtitle1: {
        fontSize: `${theme.fontSizes.md}`,
        fontWeight: `${theme.fontWeights.semiBold}`,
      },
      subtitle2: {
        fontSize: `${theme.fontSizes.sm}`,
        fontWeight: `${theme.fontWeights.semiBold}`,
      },
      subtitle3: {
        fontSize: `${theme.fontSizes.xs}`,
        fontWeight: `${theme.fontWeights.semiBold}`,
      },
      // label1 - default styles in body!
      label1: {
        fontSize: `${theme.fontSizes.md}`,
        fontWeight: `${theme.fontWeights.medium}`,
      },
      label2: {
        fontSize: `${theme.fontSizes.sm}`,
        fontWeight: `${theme.fontWeights.medium}`,
      },
      label3: {
        fontSize: `${theme.fontSizes.xs}`,
        fontWeight: `${theme.fontWeights.medium}`,
      },
      body1: {
        fontSize: `${theme.fontSizes.md}`,
        fontWeight: `${theme.fontWeights.regular}`,
      },
      body2: {
        fontSize: `${theme.fontSizes.sm}`,
        fontWeight: `${theme.fontWeights.regular}`,
      },
      body3: {
        fontSize: `${theme.fontSizes.xs}`,
        fontWeight: `${theme.fontWeights.regular}`,
      },
    },
  });

const TextStyled = styled.p`
  text-overflow: ${props => props.textOverflow};
  white-space: ${props => props.whiteSpace};
  word-break: ${props => props.wordBreak};
  ${props => props.lineClamp && LineClampStyles(props.lineClamp)};
  ${props => props.$isGradient && GradientText(`${gradients.primary}`)};
  ${props => TextVariant(props)};
  ${props => props.fullWidth && `width: 100%;`};
  opacity: ${props => props.opacity};
  ${border};
  ${color};
  ${layout};
  ${typography};
  ${flexbox};
  ${space};
  ${position};
  ${grid};
  ${system({
    textDecoration: true,
    gap: true,
  })};
`;

const Text = ({ children, ...props }) => (
  <TextStyled {...props}>{children}</TextStyled>
);

Text.propTypes = {
  children: propTypes.node.isRequired,
  $isGradient: propTypes.bool,
  lineClamp: propTypes.string,
  whiteSpace: propTypes.string,
  textOverflow: propTypes.string,
  type: propTypes.string,
  opacity: propTypes.string,
};

export default Text;
